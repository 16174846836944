// Dependencies
import React from "react"
// Components
import Header from "../components/Header"
import Meta from "../components/Meta"
// CSS
import "../css/styles.scss"

const Layout = ({ title, description, shareImage, children }) => (
  <>
    <Meta title={title} description={description} shareImage={shareImage} />
    <main>{children}</main>
  </>
)

export default Layout
