import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultTitle: title
          defaultDescription: description
          defaultShareImage: shareImage {
            fluid(maxHeight: 500) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          about {
            childMarkdownRemark {
              html
            }
          }
          links {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
